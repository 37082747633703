import {RentalHeader} from "../../../common/components/RentalHeader";
import {useEffect, useState} from "react";

import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useParams} from "react-router-dom";
import {RentalInfoBar} from "../../../common/components/RentalInfoBar";
import {RentalItemsExchangeTakeStep} from "./steps/RentalItemsExchangeTakeStep";
import {RentalItemsExchangeGiveStep} from "./steps/RentalItemsExchangeGiveStep";
import GiveItemModal from "../../../common/components/GiveItemModal";
import {useHistory} from "react-router";

import {formatCategories, submitRentalItemsExchange, validateGiveItems} from "./rentalItemsExchangePageSlice";
import {
    changeStep,
    giveItem,
    loadRentalItemsExchange,
    setCategoryMutation,
    setSubmitOption,
} from "./rentalItemsExchangePageSlice";
import {RentalActionBar} from "../../../common/components/RentalActionBar";
import TinyLoader from "../../../../common/components/General/TinyLoader";
import translate from "../../../common/helpers/utils/translate";
import {PinValidationWrapper} from "../../../../common/wrappers/PinValidationWrapper";
import {apiPartnerItemDetailById} from "../../../common/api/partner/item/apiPartnerItemDetailById";

export const RentalItemsExchangePageContainer = (props) => {

    const dispatch = useAppDispatch()
    const {rentalUuid} = useParams();
    const history = useHistory()
    const [pageLoaded, setPageLoaded] = useState(false)

    const {
        rentalContainer,
        step,
        giveItemValidation,
        rentalGiveItems,
        rentalTakeItems,
        rentalItems,
        submitOption,
        categoryMutations,
        rentalGiveItemNotifications,
        submitting,
        categories
    } = useAppSelector(store => store.rentalItemsExchangePage)

    const {
        unlinkItemModal,
        serviceModal,
        environment
    } = useAppSelector(store => store)

    useEffect(() => {
        if (rentalUuid) {
            dispatch(loadRentalItemsExchange(rentalUuid)).then(() => {
                setPageLoaded(true)
            })
        }
    }, [rentalUuid, dispatch])

    useEffect(() => {
        // reformat categories
        dispatch(formatCategories())
    }, [rentalGiveItems, rentalTakeItems, categoryMutations])

    // After unlink modal set the given item so it will be put in the correct array of objects
    useEffect(() => {

        if (unlinkItemModal.submitted && unlinkItemModal.item) {
            dispatch(giveItem({item: unlinkItemModal.item}))
        }

        async function fetchServiceItemById() {
            const response = await apiPartnerItemDetailById(parseInt(serviceModal.item.id))
            dispatch(giveItem({item: response?.data?.items?.item}))
        }

        if (serviceModal.submitted && serviceModal.item) {
            fetchServiceItemById();
        }


    }, [unlinkItemModal.submitted, serviceModal.submitted])

    const submit = () => {
        dispatch(submitRentalItemsExchange()).then(() => {
            history.push('/' + environment.slug + '/dashboard/rental/transaction-check/' + rentalContainer.rental.uuid)
        })
    }


    if (rentalContainer && pageLoaded) {
        return (
            <main>
                <PinValidationWrapper>
                    <div className="o-container o-container--primary" style={{paddingBottom: '6rem'}}>

                        <RentalHeader
                            rentalId={rentalContainer.rental.id}
                            rentalUuid={rentalContainer.rental.uuid}
                            pageTitle={translate('exchange')}
                        />

                        <RentalInfoBar rentalContainer={rentalContainer}/>

                        <RentalItemsExchangeTakeStep disabled={step !== 'take'} scanCode={props.location?.state?.code}/>

                        <RentalItemsExchangeGiveStep disabled={step !== 'give'}/>

                        <RentalActionBar
                            orderLines={rentalContainer.rentalItems}
                            showLoader={submitting}
                            submitEnabled={!!rentalGiveItems.length}
                            rentalGiveItems={rentalGiveItems}
                            rentalTakeItems={rentalTakeItems}
                            rentalItems={rentalItems}
                            rentalNotifications={rentalGiveItemNotifications}
                            categoryMutations={categoryMutations}
                            buttonText={'Omruilen'}
                            onSubmit={() => {
                                dispatch(validateGiveItems()).then(() => {
                                    if (!rentalGiveItemNotifications.filter((filter) => (filter.type === 'error' || filter.type === 'unavailable')).length) {
                                        if (giveItemValidation.completed && !submitting) {
                                            submit()
                                        } else {
                                            dispatch(changeStep('modal'))
                                        }
                                    }
                                })
                            }}
                        />
                    </div>
                </PinValidationWrapper>
            </main>
        )
    } else return <TinyLoader/>

}